import './Video.css';
import { useEffect,useState } from "react";
import db from "../../firebase";
import { collection,getDocs } from "firebase/firestore";


export default function Video({loadingSuccess}) {
    const [video, setVideo] = useState([]);

    const videoRef = collection(db, 'video');

    useEffect(()=>{
        getDocs(videoRef).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            setVideo(doc.data().video);
            loadingSuccess();
          });
        }).catch((error) => {
          console.error('Error getting documents: ', error);
        });
      },[])

    return(
        <div className="video" id="video">
        <h2>Смотрите премьеру клипа</h2>
        <div className="video_container">
          {
            video && video.map((item,index)=>{
              return(
                <iframe key={index} width="100%" className="video_height" src={item} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              )
            })
          }
            </div>
        </div>
    )
}