import './App.css';
import Header from '../Header/Header';
import Title from '../Title/Title';
import Bunner from "../Bunner/Bunner";
import Concerts from "../Concerts/Concerts";
import Music from "../Music/Music";
import Video from "../Video/Video";
import Contacts from "../contacts/Contacts";
import { useState } from 'react';
import Logo from "../../images/logo.png";

function App() {

  const [loading, setLoading] = useState({
    video: true,
    music: true,
    concerts: true,
    contacts: true
  });

  return (
    <>
    {loading.video && loading.music && loading.concerts && loading.contacts && <div className="loading">
      <img src={Logo} alt="loading"/></div>}
    <div className="container">
        <Header/>
        <Title/>
        <Bunner/>
        <Concerts loadingSuccess={()=>setLoading(loading=>({...loading,concerts: false}))}/>
        <Music loadingSuccess={()=>setLoading(loading=>({...loading,music: false}))}/>
        <Video loadingSuccess={()=>setLoading(loading=>({...loading,video: false}))}/>
        <Contacts loadingSuccess={()=>setLoading(loading=>({...loading,contacts: false}))}/>
    </div>
    </>
  );
}

export default App;
