import './ConcertCard.css';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

export default function ConcertCard({concert}) {

    const date = new Date(concert.date * 1000);

    const formattedDate = format(date, 'd MMMM', { locale: ru });
  
const utcHours = date.getUTCHours();
const utcMinutes = date.getUTCMinutes();

const formattedTimeUTC = format(new Date(date.setUTCHours(utcHours, utcMinutes, 0)), 'HH:mm');
    
     return(
        <div className="concert_card">
            <div className="concert_card_section">
            <div className="concert_card__top date">{formattedDate}</div>
            {utcMinutes!==4 &&
                <div className="concert_card__bottom date">{formattedTimeUTC}</div>}
            </div>
            <div className="concert_card_section">
            <div className="concert_card__top">{concert.city}</div>
            {concert.place && <div className="concert_card__bottom">{concert.place}</div>}
            </div>
            <div className="concert_card_section_button">
            {concert.linkTickets && <a href={concert.linkTickets} className="concert_card_button" target="_blank">Купить билеты</a>}
            </div>
        </div>
     )
}