import './Contacts.css';
import React, { useEffect, useState } from 'react';
import db from '../../firebase';
import { collection,getDocs } from 'firebase/firestore';
import Inst from "../../images/inst.svg";
import Vk from "../../images/vk.svg";
import Yandex from "../../images/yandex.svg";
import Youtube from "../../images/youtube.svg";


export default function Contacts({loadingSuccess}) {

    const [contacts, setContacts] = useState({})


    const concertsRef = collection(db, 'contacts');

    useEffect(()=>{
      getDocs(concertsRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            setContacts(doc.data().contacts);
            loadingSuccess();
        });
      }).catch((error) => {
        console.error('Error getting documents: ', error);
      });
    },[])

    return(
        <div className="contacts" id="contacts">
            <div className="contacts_wrapper">
                <div className="media">
                    <a href={contacts.instagram || ""} target="_blank" className="media_section">
                        <img src={Inst} alt="instagram"/>
                        <span>INSTAGRAM</span></a>
                    <a href={contacts.vk || ""} target="_blank" className="media_section">
                    <img src={Vk} alt="vk"/><span>ВКОНТАКТЕ</span></a>
                    <a href={contacts.yandex || ""} target="_blank" className="media_section">
                    <img src={Yandex} alt="yandex"/><span>ЯНДЕКС.МУЗЫКА</span></a>
                    <a href={contacts.youtube || ""} target="_blank" className="media_section">
                    <img src={Youtube} alt="youtube"/><span>YOUTUBE</span></a>
                </div>
                <div className="personal-contacts"><span>Контакты:</span><span>Концерты, PR - +7 (916) 887-79-18 Анастасия Галкина</span></div>
            </div>
        </div>
    )
}