import  './Header.css';
import Logo from '../../images/logo.png';


export default  function Header(){
    return (
        <header>
            <div className="header_tabs">
            <a href="#concerts">Концерты</a>
            <a href="#music">Музыка</a>
            </div>
            <img src={Logo} alt="logo" />
            <div className="header_tabs">
            <a href="#video">Видео</a>
            <a href="#contacts">Контакты</a>
            </div>
        </header>
    )
}