import "./MusicCard.css";

function transformYandexMusicUrl(originalUrl) {
    const regex = /\/album\/(\d+)\/track\/(\d+)/;
    const match = originalUrl.match(regex);
    
    if (match) {
      const albumId = match[1];
      const trackId = match[2];
      const transformedUrl = `https://music.yandex.ru/iframe/#track/${trackId}/${albumId}`;
      return transformedUrl;
    } else {
      return 'Invalid Yandex Music URL';
    }
  }


export default function MusicCard({ url }) {
    const urlTransform = transformYandexMusicUrl(url);
    console.log('originalUrl',url,'urlTransform',urlTransform)
    return(
        <div>
            <iframe frameborder="0" style={{border:'none'}} className="music_height" width="100%" src={urlTransform}/>
        </div>
    )
}