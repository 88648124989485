import { useEffect,useState } from "react";
import "./Music.css";
import MusicCard from "./MusicCard/MusicCard";
import db from "../../firebase";
import {collection,getDocs} from "firebase/firestore";

export default function Music({loadingSuccess}) {


    const musicRef = collection(db, 'music');
    const [music, setMusic] = useState([]);

    useEffect(()=>{
        getDocs(musicRef).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            setMusic(doc.data().music);
            loadingSuccess();
          });
        }).catch((error) => {
          console.error('Error getting documents: ', error);
        });
      },[])

    return (
        <div className="music" id="music">
        <h2>Слушайте треки</h2>
        <div className="concerts__list">
                {music && music.map((musicItem, index) => (
                    <MusicCard key={index} url={musicItem}/>
                ))}
            </div>
        </div>
    );
}