// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB4rytNBfzkElCGQ3uCHW20Z2mDthw4JMc",
  authDomain: "akmalsite-89625.firebaseapp.com",
  projectId: "akmalsite-89625",
  storageBucket: "akmalsite-89625.appspot.com",
  messagingSenderId: "206414817017",
  appId: "1:206414817017:web:02120f632cc845af27e428"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default getFirestore(app);