import "./Bunner.css";
import Marquee from 'react-double-marquee';

export default function Bunner() {
    return(
        <div
        style={{
            width: '100%',
            whiteSpace: 'nowrap',
               color: '#f5f5f5',
   backgroundColor: '#40464a',
   padding: '15px 0',
   fontSize: '21px',
          }}
      >
              <Marquee>
              ФИНАЛИСТ ШОУ "ГОЛОС.ДЕТИ"  |  ОФИЦИАЛЬНЫЙ САЙТ КОНЦЕРТНОГО ТУРА  |  ИСПОЛНИТЕЛЬ ХИТОВ "ВДОЛЬ ДОРОГ", "ПО ПОЛЮШКУ", "ЭТОЙ НОЧЬЮ" И ДРУГИЕ
                 </Marquee>
        </div>
    )
}